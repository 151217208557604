import { ReactNode, useEffect, useState } from "react";
import { Button } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';


import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';

export interface CustTransactionDialogProps {
    modal: boolean;
    activeStep: number;
    modalCancel: () => void;
    children?: ReactNode;
}

const steps = ['Approve Token', 'Deposit Token to LP', 'Approve LP Token', 'Deposit LP Token to Smart Vault'];

const CustTransactionDialog = (CustTransactionDialogProps: CustTransactionDialogProps) => {
    const { modal, activeStep, modalCancel } = CustTransactionDialogProps;

    useEffect(() => {
        console.log("modal");
    }, [modal]);

    return (
        <>
            <Dialog
                open={modal}
                onClose={modalCancel}>
                <DialogTitle
                    style={{
                        fontSize: "20px",
                        marginTop: "20px",
                        fontWeight: "700",
                        fontFamily: "poppins"
                    }}>Transactions</DialogTitle>
                <DialogContent>
                <Stepper activeStep={activeStep}  orientation="vertical">
                    {steps.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};
                    
                    return (
                        <Step key={label} {...stepProps}>
                        <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                    })}
                </Stepper>
                </DialogContent>
            </Dialog>
        </>
    )
}


export default CustTransactionDialog;


