import { request, gql } from "graphql-request"

export function borrowBTC(address, hash, userHealthRatioBefore, userHealthRatioAfter, amount, price) {

    var date = new Date();
    const query = gql`
    mutation {
        createPost( 
          data: {
                    title: "Borrow BTC",
                    account: "${address}",
                    content: "${hash}",
                    borrowingPosition: "ETH/BTC", 
                    healthFactor: "${userHealthRatioBefore}",
                    actionType: "Borrow BTC",
                    amount: "${amount} BTC",
                    value: "$${Number(amount * price / 100).toFixed(2)}",
                    newHealthFactor: "${userHealthRatioAfter}",
                    publishDate: "${date.toISOString()}"
              }
        ) {
          id
        }
      }
    `

    request('https://backend.loanshark.tech/api/graphql/', query).then(
        (data) => console.log(data)
    )
}

export function repayBTC(address, hash, userHealthRatioBefore, userHealthRatioAfter, amount, price) {

    var date = new Date();
    const query = gql`
    mutation {
        createPost( 
          data: {
                    title: "Repay BTC",
                    account: "${address}",
                    content: "${hash}",
                    borrowingPosition: "ETH/BTC", 
                    healthFactor: "${userHealthRatioBefore}",
                    actionType: "Repay BTC",
                    amount: "${amount} BTC",
                    value: "$${Number(amount * price / 100).toFixed(2)}",
                    newHealthFactor: "${userHealthRatioAfter}",
                    publishDate: "${date.toISOString()}"
              }
        ) {
          id
        }
      }
    `

    request('https://backend.loanshark.tech/api/graphql/', query).then(
        (data) => console.log(data)
    )
}

export function depositETH(address, hash, userHealthRatioBefore, userHealthRatioAfter, amount, price) {

    var date = new Date();
    const query = gql`
    mutation {
        createPost( 
          data: {
                    title: "Deposit ETH",
                    account: "${address}",
                    content: "${hash}",
                    borrowingPosition: "ETH/BTC", 
                    healthFactor: "${userHealthRatioBefore}",
                    actionType: "Deposit ETH",
                    amount: "${amount} ETH",
                    value: "$${Number(amount * price / 100).toFixed(2)}",
                    newHealthFactor: "${userHealthRatioAfter}",
                    publishDate: "${date.toISOString()}"
              }
        ) {
          id
        }
      }
    `

    request('https://backend.loanshark.tech/api/graphql/', query).then(
        (data) => console.log(data)
    )
}

export function withdrawETH(address, hash, userHealthRatioBefore, userHealthRatioAfter, amount, price) {

    var date = new Date();
    const query = gql`
    mutation {
        createPost( 
          data: {
                    title: "Withdraw ETH",
                    account: "${address}",
                    content: "${hash}",
                    borrowingPosition: "ETH/BTC", 
                    healthFactor: "${userHealthRatioBefore}",
                    actionType: "Withdraw ETH",
                    amount: "${amount} ETH",
                    value: "$${Number(amount * price / 100).toFixed(2)}",
                    newHealthFactor: "${userHealthRatioAfter}",
                    publishDate: "${date.toISOString()}"
              }
        ) {
          id
        }
      }
    `

    request('https://backend.loanshark.tech/api/graphql/', query).then(
        (data) => console.log(data)
    )
}

export function addSmartVaultBTC(address, hash, userHealthRatioBefore, userHealthRatioAfter, amount, price) {

  var date = new Date();
  const query = gql`
  mutation {
      createPost( 
        data: {
                  title: "Add BTC Smart Vault",
                  account: "${address}",
                  content: "${hash}",
                  borrowingPosition: "ETH/BTC", 
                  healthFactor: "${userHealthRatioBefore}",
                  actionType: "Add BTC Smart Vault",
                  amount: "${amount} BTC",
                  value: "$${Number(amount * price / 100).toFixed(2)}",
                  newHealthFactor: "${userHealthRatioAfter}",
                  publishDate: "${date.toISOString()}"
            }
      ) {
        id
      }
    }
  `

  request('https://backend.loanshark.tech/api/graphql/', query).then(
      (data) => console.log(data)
  )
}

export function addSmartVaultETH(address, hash, userHealthRatioBefore, userHealthRatioAfter, amount, price) {

  var date = new Date();
  const query = gql`
  mutation {
      createPost( 
        data: {
                  title: "Add ETH Smart Vault",
                  account: "${address}",
                  content: "${hash}",
                  borrowingPosition: "ETH/BTC", 
                  healthFactor: "${userHealthRatioBefore}",
                  actionType: "Add ETH Smart Vault",
                  amount: "${amount} ETH",
                  value: "$${Number(amount * price / 100).toFixed(2)}",
                  newHealthFactor: "${userHealthRatioAfter}",
                  publishDate: "${date.toISOString()}"
            }
      ) {
        id
      }
    }
  `

  request('https://backend.loanshark.tech/api/graphql/', query).then(
      (data) => console.log(data)
  )
}

export function leaveSmartVaultBTC(address, hash, userHealthRatioBefore, userHealthRatioAfter, amount, price) {

  var date = new Date();
  const query = gql`
  mutation {
      createPost( 
        data: {
                  title: "Leave BTC Smart Vault",
                  account: "${address}",
                  content: "${hash}",
                  borrowingPosition: "ETH/BTC", 
                  healthFactor: "${userHealthRatioBefore}",
                  actionType: "Leave BTC Smart Vault",
                  amount: "${amount} BTC",
                  value: "$${Number(amount * price / 100).toFixed(2)}",
                  newHealthFactor: "${userHealthRatioAfter}",
                  publishDate: "${date.toISOString()}"
            }
      ) {
        id
      }
    }
  `

  request('https://backend.loanshark.tech/api/graphql/', query).then(
      (data) => console.log(data)
  )
}

export function leaveSmartVaultETH(address, hash, userHealthRatioBefore, userHealthRatioAfter, amount, price) {

  var date = new Date();
  const query = gql`
  mutation {
      createPost( 
        data: {
                  title: "Leave ETH Smart Vault",
                  account: "${address}",
                  content: "${hash}",
                  borrowingPosition: "ETH/BTC", 
                  healthFactor: "${userHealthRatioBefore}",
                  actionType: "Leave ETH Smart Vault",
                  amount: "${amount} ETH",
                  value: "$${Number(amount * price / 100).toFixed(2)}",
                  newHealthFactor: "${userHealthRatioAfter}",
                  publishDate: "${date.toISOString()}"
            }
      ) {
        id
      }
    }
  `

  request('https://backend.loanshark.tech/api/graphql/', query).then(
      (data) => console.log(data)
  )
}