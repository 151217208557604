export function roundDown(number: number, decimals: number) {
    decimals = decimals || 0;
    return Number(Math.floor(number * Math.pow(10, decimals)) / Math.pow(10, decimals));
}

export function displayNumberFixDecimal(incomeNumber: string | number, decimals: number | null) {
    incomeNumber = incomeNumber.toString()
    let lastChar = incomeNumber.charAt(incomeNumber.length-1)
    if(incomeNumber==='') return incomeNumber
    if(lastChar==='.') return incomeNumber
    if(decimals===null) decimals=8
    if (typeof (incomeNumber) === 'string') incomeNumber = Number(incomeNumber)
    const decimalCount = countDecimals(incomeNumber)
    if(decimalCount<=decimals) return incomeNumber
    else return incomeNumber.toFixed(decimals)
}

// var countDecimals = function (value) {
//     if(Math.floor(value) === value) return 0;
//     return value.toString().split(".")[1].length || 0;
//     }

export function countDecimals(incomeNumber: number) {
    // if (incomeNumber === 'string') incomeNumber = Number(incomeNumber)
    if (Math.floor(incomeNumber) === incomeNumber) return 0;
    return incomeNumber.toString().split(".")[1].length || 0;
}


export function decimalNumberRegExpression(inputString:string){
    let reg = new RegExp('^[0-9]+$')
    return reg.test(inputString)
}

export function numberRegExpression(inputString:string){
    let reg = new RegExp('^[0-9]+$')
    return reg.test(inputString)
}
// if(e.target.value === '' || (RegExp('^[0-9]+$').test(e.target.value) === true) ){


export function numberChecking(inputString:string){
    let regExp: RegExp = /^\d+(\.\d)?\d*$/;
    let regExp2: RegExp = /^\d+(\.)$/;
    return (regExp.test(inputString) || regExp2.test(inputString))
}